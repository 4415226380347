const default_template = {
    positions: {
        reminderType: { x: 30, y: 80 },
        invoiceDetails: { x: 33, y: 130 },
        invoiceDetailsCanvas: [
            {
                type: "rect",
                x: 30,
                y: 130,
                w: 170,
                h: 105,
                r: 3,
                lineColor: "black"
            }
        ],
        qrcodeInfo: { x: 415, y: 10 },
        qrcode: { x: 515, y: 30 },
        companyInfo: { x: 380, y: 140 },
        companyInfoCanvas: [
            {
                type: "rect",
                x: 380,
                y: 140,
                w: 180,
                h: 100,
                r: 3,
                lineColor: "black"
            }
        ],
        logo: { x: 205, y: 0 },
    }, footer: function(currentPage, pageCount, options, toCurrency, totalDebit, VATBase, body, settings) {
        return [{
            layout: "noBorders",
            table: {
                headerRows: 1,
                widths: [200, 180, "auto"],
                body: [
                    [{
                        layout: "noBorders",
                        table: {
                            headerRows: 1,
                            widths: ["*"],
                            body: [
                                [{ text: "Openstaand saldo: ", style: "totals" }],
                                [{ text: toCurrency(totalDebit), style: "totals" }],
                            ]
                        },

                    }, {
                        layout: "noBorders",
                        table: {
                            headerRows: 0,
                            widths: ["auto", "auto", "auto"],
                            body: VATBase
                        },
                        margin: [0, 0, 0, 0]
                    }, {
                        layout: "noBorders",
                        table: {
                            headerRows: 1,
                            widths: ["*", "*"],
                            body: body
                        },
                        margin: [0, 0, 0, 0]
                    }]
                ]
            },
            margin: [20, 0, 0, 0]
        },
        {
            canvas: [
                {
                    type: "rect",
                    x: 0,
                    y: 0,
                    w: 150,
                    h: 50,
                    r: 3,
                    lineColor: "black"
                }
            ],
            absolutePosition: { x: 15, y: 2 }
        },
        {
            canvas: [
                {
                    type: "rect",
                    x: 0,
                    y: 0,
                    w: 570,
                    h: 85,
                    r: 3,
                    lineColor: "black"
                }
            ],
            absolutePosition: { x: 10, y: 0 }
        },
        { text: options.stationary ? undefined : `${settings.company.name}`, margin: [0, 5, 0, 0], style: "footerHeader" },
        {
            text: [{ text: options.stationary ? undefined : `A: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.address} ${settings.company.postalcode} ${settings.company.city} `, style: "footerInfo" },
            { text: options.stationary ? undefined : `T: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.phone} `, style: "footerInfo" },
            { text: options.stationary ? undefined : `E: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.email} `, style: "footerInfo" },
            { text: options.stationary ? undefined : `BTW: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.VATNumber} `, style: "footerInfo" }]
        },
        {
            text: [{ text: options.stationary ? undefined : `IBAN: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.IBAN1} `, style: "footerInfo" },
            { text: options.stationary ? undefined : `BIC: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.BIC1} `, style: "footerInfo" },
            { text: options.stationary || !settings.company.IBAN2 ? undefined : `IBAN: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary || !settings.company.IBAN2 ? undefined : `${settings.company.IBAN2} `, style: "footerInfo" },
            { text: options.stationary || !settings.company.BIC2 ? undefined : `BIC: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary || !settings.company.BIC2 ? undefined : `${settings.company.BIC2} `, style: "footerInfo" },
            { text: options.stationary ? undefined : `WWW: `, margin: [0, 5, 0, 0], style: "footerHeader" }, { text: options.stationary ? undefined : `${settings.company.website} `, style: "footerInfo" }]
        },
        { text: options.stationary ? undefined : `${settings.company.conditions}`, style: "conditions" },
        { text: options.stationary ? undefined : `Let op nieuwe bankrekeningnr`, style: "alert" },
        { text: `pagina ${currentPage} van ${pageCount}`, alignment: "right", margin: [0, 5, 20, 0], fontSize: 8 }
        ]
    }, styles: {
        company: {
            alignment: "right"
        },
        header: {
            fontSize: 30,
            bold: true
        },
        subHeader: {
            fontSize: 20,
            bold: true
        },
        companyInfo: {
            fontSize: 10
        },
        footerHeader: {
            fontSize: 9,
            bold: true,
            alignment: "center"
        },
        footerInfo: {
            fontSize: 8,
            alignment: "center"
        },
        conditions: {
            fontSize: 7,
            alignment: "center"
        },
        info: {
            fontSize: 8
        },
        alert: {
            fontSize: 10,
            bold: true,
            alignment: "center",
            color: "red"
        },
        totals: {
            fontSize: 15,
            bold: true
        },
        tableHeader: {
            bold: true
        }
    }
};
export default default_template;