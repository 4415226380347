<template>
  <v-dialog :value="clientModal" persistent fullscreen>
    <v-card>
      <v-card-title>{{ thisClient.name }}</v-card-title>
      <v-card-text>
        <v-form v-model="formIsValid" ref="form">
          <v-tabs v-model="tab" show-arrows>
            <v-tab key="Info">Info</v-tab>
            <v-tab key="Logistiek">Logistiek</v-tab>
            <v-tab @click="fetchFixedPrices" key="Prijsafspraken"
              >Prijsafspraken</v-tab
            >
            <v-tab @click="fetchClientAccount" key="Rekening">Rekening</v-tab>
            <v-tab key="Betalingen">Betalingen</v-tab>
            <v-tab @click="fetchOrders" key="Orders">Orders</v-tab>
            <v-tab @click="fetchInvoices" key="Facturen">Facturen</v-tab>
            <v-tab key="Sjablonen">Sjablonen</v-tab>
            <v-tab key="Account">Account</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="Info">
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="4">
                  <search-COC
                    :searchString="thisClient.name"
                    @setSearchString="setSearchString"
                    @get-results="setCOC"
                    @clear-input="clearCOC"
                  ></search-COC>
                  <v-text-field
                    dense
                    label="Contactpersoon"
                    ref="contact"
                    v-model="thisClient.contact"
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="Adres"
                    ref="address"
                    v-model="thisClient.address"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="Postcode"
                    ref="postalcode"
                    :rules="[rules.required]"
                    v-model="thisClient.postalcode"
                    required
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="Stad"
                    ref="city"
                    :rules="[rules.required]"
                    v-model="thisClient.city"
                    required
                  ></v-text-field>
                  <v-autocomplete
                    dense
                    label="Land"
                    ref="country"
                    :rules="[rules.required]"
                    @change="setExport"
                    v-model="thisClient.country"
                    :items="countries"
                    item-text="cname"
                    item-value="ccode"
                    autocomplete="new-password"
                    required
                  ></v-autocomplete>
                  <v-text-field
                    dense
                    label="Telefoon"
                    ref="phone"
                    v-model="thisClient.phone"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="Mobiel"
                    ref="mobile"
                    v-model="thisClient.mobile"
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="E-mail"
                    ref="email"
                    v-model="thisClient.email"
                    :rules="[rules.email, rules.required]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="BTW-nummer"
                    ref="VATNumber"
                    v-model="thisClient.VATNumber"
                    :rules="[requireVAT, rules.VATNumber]"
                    :prefix="thisClient.country"
                    hint="Vul de BTW nummer in zonder punten en spaties"
                  ></v-text-field>
                  <v-select
                    ref="payTerms"
                    :items="[0, 14, 30, 60]"
                    v-model="thisClient.payTerms"
                    label="Betalingstermijn"
                  ></v-select>
                  <v-select
                    ref="invoiceFrequency"
                    :items="['Dagelijks', 'Wekelijks', 'Maandelijks']"
                    v-model="thisClient.invoiceFrequency"
                    label="Factuur frequentie"
                  ></v-select>
                  <v-text-field
                    dense
                    label="KvK-nummer"
                    ref="COCNumber"
                    v-model="thisClient.COCNumber"
                  ></v-text-field>
                  <v-text-field
                    dense
                    label="Bankrekening"
                    ref="bankAccount"
                    v-model="thisClient.bankAccount"
                  ></v-text-field>
                  <v-checkbox
                    label="Prijs incl. BTW"
                    v-model="thisClient.includeVAT"
                  ></v-checkbox>
                  <v-checkbox
                    label="Export"
                    v-model="thisClient.export"
                  ></v-checkbox>
                  <v-select
                    :disabled="!thisClient.export"
                    :items="exportOptions"
                    item-text="text"
                    item-value="value"
                    v-model="thisClient.exportEU"
                    label="Export-type"
                  ></v-select>
                  <v-text-field
                    dense
                    label="EORI-nummer"
                    ref="EORINumber"
                    v-model="thisClient.EORINumber"
                  ></v-text-field>
                  <v-select
                    :disabled="!thisClient.export"
                    :items="incoterms"
                    v-model="thisClient.incoterm"
                    label="Incoterm"
                  ></v-select>
                  <v-checkbox
                    label="Toon prijzen"
                    v-model="thisClient.includePrices"
                  ></v-checkbox>
                  <v-checkbox
                    label="Actief"
                    v-model="thisClient.active"
                  ></v-checkbox
                ></v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="Logistiek">
              <v-row class="align-start">
                <v-col cols="6" sm="1">
                  <v-checkbox
                    hide-details
                    class="shrink mr-2 mt-0"
                    dense
                    label="Export"
                    ref="export"
                    v-model="thisClient.export"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="5">
                  <v-select
                    dense
                    :disabled="!thisClient.export"
                    :items="exportOptions"
                    item-text="text"
                    item-value="value"
                    v-model="thisClient.exportEU"
                    label="Export-type"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-autocomplete
                    dense
                    label="Route"
                    ref="defaultRoute"
                    v-model="thisClient.defaultRoute"
                    :items="routes"
                    autocomplete="new-password"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-checkbox
                    label="Leveradres is anders dan factuuradres "
                    v-model="thisClient.shipping"
                  ></v-checkbox
                ></v-col>
                <v-col></v-col>
              </v-row>
              <v-col v-if="thisClient.shipping">
                <h2>Leveradres</h2>
                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      label="Adres"
                      ref="shippingAddress"
                      v-model="thisClient.shippingAddress"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      label="Postcode"
                      ref="shippingPostalcode"
                      :rules="[rules.required]"
                      v-model="thisClient.shippingPostalcode"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      label="Stad"
                      ref="shippingCity"
                      :rules="[rules.required]"
                      v-model="thisClient.shippingCity"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      dense
                      label="Land"
                      ref="shippingCountry"
                      :rules="[rules.required]"
                      v-model="thisClient.shippingCountry"
                      :items="countries"
                      item-text="cname"
                      item-value="ccode"
                      autocomplete="new-password"
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-tab-item>
            <v-tab-item key="Prijsafspraken">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Zoek naar artikel"
                    v-model="searchItem"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="openFixedPriceModal"
                    >Nieuwe prijsafspraak</v-btn
                  >
                </v-col>
              </v-row>
              <v-data-table
                :headers="fixedPricesHeaders"
                :items="fixedPrices"
                class="elevation-1"
                :search="searchItem"
              >
                <template v-slot:[`item.price`]="props">
                  <v-edit-dialog
                    cancel-text="Annuleren"
                    save-text="Opslaan"
                    large
                    @save="updateFixedPrice(props.item)"
                  >
                    {{ toCurrency(props.item.price) }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.price"
                        label="Prijs"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.created`]="{ item }">
                  <td>
                    {{ toDateString(item.created) }}
                  </td>
                </template>
                <template v-slot:[`item.updated`]="{ item }">
                  <td v-if="item.updated">
                    {{ toDateString(item.updated) }}
                  </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="openSalePriceModal(item)">mdi-sale</v-icon>
                  <v-icon @click="deleteFixedPrice(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="Rekening">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-list dense>
                      <v-card-title>Orders</v-card-title>
                      <v-list-item>
                        <v-list-item-content>Totaal excl.:</v-list-item-content>
                        <v-list-item-content class="align-end">{{
                          toCurrency(orderNetTotal)
                        }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>Totaal incl.:</v-list-item-content>
                        <v-list-item-content class="align-end">{{
                          toCurrency(orderGrossTotal)
                        }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content
                          >Te factureren:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">{{
                          toCurrency(orderDebit)
                        }}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-list dense>
                      <v-card-title>Facturen</v-card-title>
                      <v-list-item>
                        <v-list-item-content>Totaal excl.:</v-list-item-content>
                        <v-list-item-content class="align-end">{{
                          toCurrency(invoiceNetTotal)
                        }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>Totaal incl.:</v-list-item-content>
                        <v-list-item-content class="align-end">{{
                          toCurrency(invoiceGrossTotal)
                        }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>Openstaand:</v-list-item-content>
                        <v-list-item-content class="align-end">{{
                          toCurrency(invoiceDebit)
                        }}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-list dense>
                      <v-card-title>Te factureren</v-card-title>
                      <v-list-item-content>
                        <v-data-table
                          :headers="openOrdersHeaders"
                          :items="openOrders"
                          :items-per-page="50"
                          class="elevation-1"
                          item-key="_id"
                        >
                          <template v-slot:[`item.status`]="{ item }">
                            <img :src="`/img/${item.status}.svg`" />
                          </template>
                          <template v-slot:[`item.date`]="{ item }">{{
                            toDateString(item.date)
                          }}</template>
                          <template v-slot:[`item.grossTotal`]="{ item }">{{
                            toCurrency(item.grossTotal)
                          }}</template>
                          <template v-slot:[`body.append`]="{}">
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <v-btn-toggle>
                                  <v-btn
                                    color="primary"
                                    @click="openPrintOpenOrdersModal"
                                    >Afdrukken</v-btn
                                  >
                                  <v-btn
                                    color="primary"
                                    @click="openEmailOpenOrdersModal"
                                    >Mailen</v-btn
                                  >
                                </v-btn-toggle>
                              </td>
                              <td>Te factureren:</td>
                              <td>
                                <b>{{ toCurrency(orderDebit) }}</b>
                              </td>
                              <td></td>
                              <td>
                                <b></b>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-list-item-content>
                    </v-list>
                  </v-card>
                  <v-card>
                    <v-list dense>
                      <v-card-title>Openstaande facturen</v-card-title>
                      <v-list-item-content>
                        <v-data-table
                          :headers="outstandingInvoicesHeaders"
                          :items="outstandingInvoices"
                          :items-per-page="50"
                          class="elevation-1"
                          item-key="_id"
                        >
                          <template v-slot:[`item.status`]="{ item }">
                            <img :src="`/img/${item.status}.svg`" />
                          </template>
                          <template v-slot:[`item.date`]="{ item }">{{
                            toDateString(item.date)
                          }}</template>
                          <template v-slot:[`item.dueDate`]="{ item }">{{
                            toDateString(item.dueDate)
                          }}</template>
                          <template v-slot:[`item.credit`]="{ item }">{{
                            toCurrency(item.credit)
                          }}</template>
                          <template v-slot:[`item.debit`]="{ item }">{{
                            toCurrency(item.debit)
                          }}</template>
                          <template v-slot:[`item.grossTotal`]="{ item }">{{
                            toCurrency(item.grossTotal)
                          }}</template>
                          <template v-slot:[`body.append`]="{}">
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <v-btn-toggle>
                                  <v-btn
                                    color="primary"
                                    @click="openPrintOutstandingInvoicesModal"
                                    >Afdrukken</v-btn
                                  >
                                  <v-btn
                                    color="primary"
                                    @click="openEmailOutstandingInvoicesModal"
                                    >Mailen</v-btn
                                  >
                                </v-btn-toggle>
                              </td>
                              <td>
                                <b></b>
                              </td>
                              <td></td>
                              <td>
                                <b></b>
                              </td>
                              <td>Openstaand:</td>
                              <td>
                                <b>{{ toCurrency(invoiceDebit) }}</b>
                              </td>
                              <td></td>
                              <td>
                                <b></b>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-list-item-content>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="Betalingen">
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <div class="d-flex">
                    <v-checkbox
                      label="Alles"
                      @change="fetchInvoicePayments"
                      v-model="allDates"
                    ></v-checkbox>
                    <date-picker
                      :value="fromDate"
                      label="Datum"
                      :dateFormatted="fromDateFormatted"
                      :disabled="allDates"
                      @changeDate="changeFromDate"
                    ></date-picker>
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <date-picker
                    :value="toDate"
                    label="t/m"
                    :dateFormatted="toDateFormatted"
                    :disabled="allDates"
                    @changeDate="changeToDate"
                  ></date-picker>
                </v-col>
              </v-row>
              <v-data-table
                :headers="paymentHeaders"
                :items="payments"
                class="elevation-1"
              >
                <template v-slot:[`item.date`]="{ item }">
                  {{ toDateString(item.date) }}
                </template>
                <template v-slot:[`item.amount`]="{ item }">{{
                  toCurrency(item.amount)
                }}</template>
                <template v-slot:[`body.append`]="{}">
                  <tr>
                    <td>
                      <v-btn color="primary" @click="openPrintPaymentsModal"
                        >Afdrukken</v-btn
                      >
                    </td>
                  </tr></template
                >
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="Orders">
              <v-data-table
                :headers="orderHeaders"
                :items="orders"
                :page.sync="orderOptions.page"
                :items-per-page.sync="orderOptions.itemsPerPage"
                @update:page="fetchOrders"
                @update:items-per-page="fetchOrders"
                :server-items-length="ordersLength"
                :footer-props="{ showFirstLastPage: true }"
                class="elevation-1"
                item-key="_id"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <img :src="`/img/${item.status}.svg`" />
                </template>
                <template v-slot:[`item.date`]="{ item }">{{
                  toDateString(item.date)
                }}</template>
                <template v-slot:[`item.grossTotal`]="{ item }">{{
                  toCurrency(item.grossTotal)
                }}</template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="Facturen">
              <v-data-table
                :headers="invoiceHeaders"
                :items="invoices"
                :page.sync="invoiceOptions.page"
                :items-per-page.sync="invoiceOptions.itemsPerPage"
                @update:page="fetchInvoices"
                @update:items-per-page="fetchInvoices"
                :server-items-length="invoicesLength"
                :footer-props="{ showFirstLastPage: true }"
                class="elevation-1"
                item-key="_id"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <img :src="`/img/${item.status}.svg`" />
                </template>
                <template v-slot:[`item.date`]="{ item }">{{
                  toDateString(item.date)
                }}</template>
                <template v-slot:[`item.dueDate`]="{ item }">{{
                  toDateString(item.dueDate)
                }}</template>
                <template v-slot:[`item.grossTotal`]="{ item }">{{
                  toCurrency(item.grossTotal)
                }}</template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="Sjablonen">
              <v-select
                label="Factuur sjabloon"
                :items="invoiceTemplates"
                v-model="thisClient.invoiceTemplate"
              ></v-select>
            </v-tab-item>
            <v-tab-item key="Account">
              <v-row class="d-flex justify-center mt-2">
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    filled
                    label="Gebruikersnaam"
                    v-model="thisClient.username"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    filled
                    label="Wachtwoord"
                    v-model="thisClient.newPassword"
                    type="password"
                  ></v-text-field>
                  <v-select
                    label="Rechten"
                    v-model.number="thisClient.scope"
                    :items="scopes"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <v-btn color="primary" @click="changeCredentials"
                    >Wijzigen</v-btn
                  >
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn
              v-if="thisClient._id"
              color="primary"
              tile
              @click="openPromptModal()"
              >Verwijder</v-btn
            >
            <v-btn color="primary" tile @click="closeClientModal"
              >Annuleer</v-btn
            >
            <v-btn color="primary" tile @click="insertClient">Opslaan</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-card-actions>
    </v-card>
    <prompt-modal
      v-if="promptModal"
      @confirm="deleteClient"
      @cancel="closePromptModal"
      :promptModal="promptModal"
      promptTitle="Verwijder klant"
      promptText="Weet u zeker dat u deze klant wilt verwijderen?"
    >
      <table>
        <tr>
          <th>Klantnr:</th>
          <td>{{ thisClient.clientNumber }}</td>
        </tr>
        <tr>
          <th>Naam:</th>
          <td>{{ thisClient.name }}</td>
        </tr>
      </table>
    </prompt-modal>
    <fixed-price-modal
      v-if="fixedPriceModal"
      @insert-fixed-price="insertFixedPrice"
      @close-fixed-price-modal="closeFixedPriceModal"
      :client="client"
      :fixedPriceModal="fixedPriceModal"
      :fixedPricesList="fixedPricesList"
    >
    </fixed-price-modal>
    <sale-price-modal
      v-if="salePriceModal"
      @insert-sale-price="insertSalePrice"
      @close-sale-price-modal="closeSalePriceModal"
      :salePriceModal="salePriceModal"
      :item="item"
    >
    </sale-price-modal>
    <print-open-orders-modal
      v-if="printOpenOrdersModal"
      :printOpenOrdersModal="printOpenOrdersModal"
      :client="client"
      :data="openOrders"
      @close-print-open-orders-modal="printOpenOrdersModal = false"
    ></print-open-orders-modal>
    <email-open-orders-modal
      v-if="emailOpenOrdersModal"
      :emailOpenOrdersModal="emailOpenOrdersModal"
      :client="client"
      :data="openOrders"
      @close-email-open-orders-modal="emailOpenOrdersModal = false"
    ></email-open-orders-modal>
    <print-outstanding-invoices-modal
      v-if="printOutstandingInvoicesModal"
      :printOutstandingInvoicesModal="printOutstandingInvoicesModal"
      :client="client"
      :data="outstandingInvoices"
      @close-print-outstanding-invoices-modal="
        printOutstandingInvoicesModal = false
      "
    ></print-outstanding-invoices-modal>
    <email-outstanding-invoices-modal
      v-if="emailOutstandingInvoicesModal"
      :emailOutstandingInvoicesModal="emailOutstandingInvoicesModal"
      :client="client"
      :data="outstandingInvoices"
      @close-email-outstanding-invoices-modal="
        emailOutstandingInvoicesModal = false
      "
    ></email-outstanding-invoices-modal>
    <print-payments-modal
      v-if="printPaymentsModal"
      :printPaymentsModal="printPaymentsModal"
      :client="client"
      :payments="payments"
      @close-print-payments-modal="printPaymentsModal = false"
    ></print-payments-modal>
    <preview-order-modal
      v-if="previewOrderModal"
      :previewOrderModal="previewOrderModal"
      :dataUrl="dataUrl"
      @close-print-outstanding-invoices-modal="previewOrderModal = false"
    ></preview-order-modal>
    <preview-invoice-modal
      v-if="previewInvoiceModal"
      :previewInvoiceModal="previewInvoiceModal"
      :dataUrl="dataUrl"
      @close-print-outstanding-invoices-modal="previewInvoiceModal = false"
    ></preview-invoice-modal>
  </v-dialog>
</template>
<script>
import { countries, euCountries } from "../js/countries"
import datePicker from "../components/datePicker.vue"
import searchCOC from '@/components/searchCOC.vue'
import promptModal from "../components/promptModal"
import printOpenOrdersModal from '@/components/printOpenOrdersModal.vue'
import emailOpenOrdersModal from '@/components/emailOpenOrdersModal.vue'
import printOutstandingInvoicesModal from "../components/printOutstandingInvoicesModal"
import emailOutstandingInvoicesModal from "../components/emailOutstandingInvoicesModal"
import printPaymentsModal from '@/components/printPaymentsModal.vue'
import previewOrderModal from "../components/previewOrderModal"
import previewInvoiceModal from "../components/previewInvoiceModal"
import fixedPriceModal from "../components/fixedPriceModal"
import salePriceModal from '@/components/salePriceModal.vue'
import { errorHandler, fetchGET, fetchPOST, successHandler, toDateString, toCurrency } from '../js/functions'
export default {
  name: "clientModal",
  props: {
    tabNumber: {
      type: Number,
      default: null
    },
    client: Object,
    clientModal: Boolean,
  },
  data() {
    return {
      item: {},
      exportOptions: [{ text: 'Binnen EU', value: true }, { text: 'Buiten EU', value: false }],
      printOpenOrdersModal: false,
      emailOpenOrdersModal: false,
      printOutstandingInvoicesModal: false,
      emailOutstandingInvoicesModal: false,
      printPaymentsModal: false,
      previewOrderModal: false,
      previewInvoiceModal: false,
      dataUrl: "",
      tab: this.tabNumber,
      thisClient: JSON.parse(JSON.stringify(this.client)),
      orders: [],
      invoices: [],
      fixedPrices: [],
      fixedPricesList: [],
      openOrders: [],
      outstandingInvoices: [],
      orderNetTotal: 0,
      orderGrossTotal: 0,
      orderDebit: 0,
      orderOptions: { itemsPerPage: 10, page: 1, sortBy: ["orderNumber"] },
      ordersLength: 0,
      invoiceNetTotal: 0,
      invoiceGrossTotal: 0,
      invoiceDebit: 0,
      invoiceOptions: { itemsPerPage: 10, page: 1, sortBy: ["invoiceNumber"] },
      invoicesLength: 0,
      openOrdersHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Totaal", value: "grossTotal" },
      ],
      outstandingInvoicesHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Vervaldatum", value: "dueDate" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Betaald", value: "credit" },
        { text: "Openstaand", value: "debit" }
      ],
      invoiceHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Vervaldatum", value: "dueDate" },
        { text: "Totaalbedrag", value: "grossTotal" }
      ],
      orderHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Totaalbedrag", value: "grossTotal" }
      ],
      fixedPricesHeaders: [
        { text: "Artikelnr", value: "itemNumber", filterable: false },
        { text: "Artikel", value: "itemName", filterable: true },
        { text: "Prijs", value: "price", filterable: false },
        { text: "Aangemaakt", value: "created", filterable: false },
        { text: "Gewijzigd", value: "updated", filterable: false },
        { text: "Aanbieding", value: "salePrice", filterable: false },
        { text: "", value: "actions" }
      ],
      paymentHeaders: [
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Datum", value: "date" },
        { text: "Bedrag", value: "amount", },
        { text: "Betalingswijze", value: "payMethod" },
      ],
      payments: [],
      allDates: false,
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      fromDatePicker: false,
      toDatePicker: false,
      promptModal: false,
      searchItem: "",
      searchString: "",
      fixedPriceModal: false,
      salePriceModal: false,
      formIsValid: false,
      rules: {
        required: value => !!value || "Veld verplicht.",
        counter: value => value.length <= 20 || "Max 20 karakters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/
          return (pattern.test(value) || !value) || "Onjuist e-mailadres."
        },
        VATNumber: value => {
          const pattern = /^\d{8,12}$/
          return (pattern.test(value) || !value) || "Onjuist BTW-nummer."
        },
      },
      countries: countries,
      scopes: [
        { text: "Gebruiker", value: 1 },
        { text: "Admin", value: 2 },
      ],
      incoterms: [
        { text: "DAP (Delivered At Place)", value: "DAP" }
      ]
    }
  },
  methods: {
    toCurrency(n) {
      return toCurrency(n)
    },
    toDateString(val) {
      return toDateString(val)
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.fetchInvoicePayments()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.fetchInvoicePayments()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    setExport(e) {
      if (e === "NL") {
        this.thisClient.export = false
        this.thisClient.exportEU = ""
      }
      else {
        this.thisClient.export = true
        this.thisClient.exportEU = euCountries.includes(e) ? true : false
      }
    },
    setItem(item) {
      this.item = item
    },
    setSearchString(val) {
      this.thisClient.name = val
    },
    setCOC(val) {
      let street = val._embedded.hoofdvestiging.adressen[0].straatnaam
      let houseNumber = val._embedded.hoofdvestiging.adressen[0].huisnummer
      let suffix = val._embedded.hoofdvestiging.adressen[0].huisnummerToevoeging || ""
      this.thisClient.address = `${street} ${houseNumber} ${suffix}`
      this.thisClient.postalcode = val._embedded.hoofdvestiging.adressen[0].postcode
      this.thisClient.city = val._embedded.hoofdvestiging.adressen[0].plaats
      this.thisClient.country = "NL"
      this.thisClient.export = false
      this.thisClient.exportEU = ""
      this.thisClient.COCNumber = val.kvkNummer
    },
    clearInput() {
      this.searchResults = false
    },
    clearCOC() {
      this.searchString = ""
    },
    openPromptModal() {
      this.promptModal = true
    },
    closePromptModal() {
      this.promptModal = false
      this.$emit("close-client-modal")
    },
    async fetchClientAccount() {
      try {
        let response = await fetchGET('clientAccount', this.thisClient.clientNumber)
        console.log(response)
        this.orderNetTotal = response[0].value.length ? response[0].value[0].netTotal : 0
        this.orderGrossTotal = response[0].value.length ? response[0].value[0].grossTotal : 0
        this.orderDebit = response[0].value.length ? response[0].value[0].debit : 0
        this.invoiceNetTotal = response[1].value.length ? response[1].value[0].netTotal : 0
        this.invoiceGrossTotal = response[1].value.length ? response[1].value[0].grossTotal : 0
        this.invoiceDebit = response[1].value.length ? response[1].value[0].debit : 0
        this.openOrders = response[2].value
        this.outstandingInvoices = response[3].value
      } catch (e) {
        errorHandler(e, "Kan rekening niet ophalen")
      }
    },
    async fetchOrders() {
      try {
        let response = await fetchGET("clientOrders", { clientNumber: this.thisClient.clientNumber, options: this.orderOptions })
        console.log(response)
        this.orders = response[0].results
        this.ordersLength = response[0].length[0].length
      } catch (e) {
        errorHandler(e, "Kan facturen niet ophalen")
      }
    },
    async fetchInvoices() {
      try {
        let response = await fetchGET("clientInvoices", { clientNumber: this.thisClient.clientNumber, options: this.invoiceOptions })
        console.log(response)
        this.invoices = response[0].results
        this.invoicesLength = response[0].length.length ? response[0].length[0].length : 0
      } catch (e) {
        errorHandler(e, "Kan facturen niet ophalen")
      }
    },
    async insertClient() {
      try {
        let response = { valid: true }
        this.$refs.form.validate()
        if (this.formIsValid) {
          // if (this.thisClient.VATNumber && this.thisClient.exportEU) response = await fetchGET("validateVAT", { data: this.thisClient.country + this.thisClient.VATNumber })
          if (response.valid) {
            if (this.thisClient._id) this.$emit("update-client", this.thisClient)
            else this.$emit("insert-client", this.thisClient)
          }
          else throw "BTW onjuist"
        }
      } catch (e) {
        errorHandler(e, e)
      }
    },
    deleteClient() {
      this.promptModal = false
      this.$emit("delete-client", this.thisClient._id)
    },
    resetClient() {
      this.$refs.form.reset()
    },
    checkForDuplicates(thisItem) {
      let items = this.fixedPrices
      let index = items.findIndex(item => item.itemNumber == thisItem.itemNumber)
      if (index > -1) return true
      return false
    },
    async fetchFixedPrices() {
      if (this.thisClient.clientNumber) {
        try {
          this.fixedPrices = await fetchGET('clientFixedPrices', this.thisClient.clientNumber)
        } catch (e) {
          errorHandler(e, "Kan prijsafspraken niet ophalen")
        }
      }
    },
    async insertFixedPrice(item, fixedPrice) {
      try {
        let duplicates = this.checkForDuplicates(item)
        if (!duplicates) {
          let data = {
            itemName: item.name,
            itemNumber: item.itemNumber,
            clientName: this.thisClient.name,
            clientNumber: this.thisClient.clientNumber,
            price: fixedPrice,
            created: new Date()
          }
          let response = await fetchPOST('insertFixedPrice', data)
          if (response.result.n == 0) throw "insertFixedPrice"
          this.fixedPrices.push(data)
          this.fixedPricesList.push(item)
          successHandler("Prijsafspraak opgeslagen")
        }
        else alert(` ${item.name} bestaat al`)
      } catch (e) {
        errorHandler(e, "Prijsafspraak niet opgeslagen")
      }
    },
    async updateFixedPrice(item) {
      try {
        item.price = item.price.replace(",", ".")
        let index = this.fixedPrices.findIndex(fix => fix.itemNumber == item.itemNumber)
        if (index > -1) {
          let response = await fetchPOST('updateFixedPrice', item)
          if (response.result.nModifief == 0) throw "updateFixedPrice"
          Object.assign(this.fixedPrices[index], item)
        }
        successHandler("Prijsafspraak gewijzigd")
      } catch (e) {
        errorHandler(e, "Prijsafspraak niet gewijzigd")
      }
    },
    async deleteFixedPrice(item) {
      try {
        let response = await fetchPOST('deleteFixedPrice', item)
        if (response.result.n == 0) throw "deleteFixedPrice"
        let index = this.fixedPrices.findIndex(fix => fix.itemNumber == item.itemNumber)
        if (index > -1) this.fixedPrices.splice(index, 1)
        successHandler("Prijsafspraak verwijderd")
      } catch (e) {
        errorHandler(e, 'Prijsafspraak niet verwijderd')
      }
    },
    async insertSalePrice(item) {
      try {
        let data = {
          itemName: item.itemName,
          itemNumber: item.itemNumber,
          clientName: this.thisClient.name,
          clientNumber: this.thisClient.clientNumber,
          salePrice: item.salePrice,
          fromDate: item.fromDate,
          toDate: item.toDate
        }
        console.log(data)
        let response = await fetchPOST('insertSalePrice', data)
        if (response.result.nModified == 0) throw "insertSalePrice"
        successHandler("Aanbieding opgeslagen")
      } catch (e) {
        errorHandler(e, "Aanbieding niet opgeslagen")
      }
    },
    updateSalePrice() {
    },
    deleteSalePrice() {
    },
    openFixedPriceModal() {
      this.fixedPriceModal = true
    },
    closeFixedPriceModal() {
      this.fixedPriceModal = false
      this.fixedPricesList = []
    },
    openSalePriceModal(item) {
      this.item = item
      this.salePriceModal = true
    },
    closeSalePriceModal() {
      this.salePriceModal = false
    },
    closeClientModal() {
      this.$emit("close-client-modal")
    },
    openPrintOpenOrdersModal() {
      this.printOpenOrdersModal = true
    },
    openEmailOpenOrdersModal() {
      this.emailOpenOrdersModal = true
    },
    openPrintOutstandingInvoicesModal() {
      this.printOutstandingInvoicesModal = true
    },
    openEmailOutstandingInvoicesModal() {
      this.emailOutstandingInvoicesModal = true
    },
    openPrintPaymentsModal() {
      this.printPaymentsModal = true
    },
    async fetchInvoicePayments() {
      try {
        let data = {
          clientNumber: this.thisClient.clientNumber,
          allDates: this.allDates,
          fromDate: this.fromDate,
          toDate: this.toDate,
        }
        let payments = await fetchGET('invoicePayments', data)
        this.payments = payments.sort((a, b) => a.date.localeCompare(b.date))
      } catch (e) {
        errorHandler(e, "Kan betalingen niet ophalen")
      }
    },
    async changeCredentials() {
      try {
        let result = await fetchPOST("changeCredentials", { ...this.thisClient, username: this.thisClient.username, password: this.thisClient.newPassword })
        if (result.nModified === 0) throw "changeCredentials"
        successHandler("Gebruikersnaam/wachtwoord gewijzigd")
      } catch (e) {
        errorHandler(e, "Gebruikersnaam/wachtwoord niet gewijzigd")
      }
    },
  },
  computed: {
    routes() {
      return this.$store.state.settings.routes
    },
    requireVAT() {
      if (this.thisClient.export && this.thisClient.exportEU && !this.thisClient.VATNumber) return "Veld verplicht"
      return true
    },
    invoiceTemplates() {
      return this.$store.state.settings.invoiceTemplates
    },
  },
  mounted() {
    if (this.tab === 3) this.fetchClientAccount()
  },
  components: {
    datePicker,
    promptModal,
    searchCOC,
    fixedPriceModal,
    salePriceModal,
    printOpenOrdersModal,
    emailOpenOrdersModal,
    printOutstandingInvoicesModal,
    emailOutstandingInvoicesModal,
    printPaymentsModal,
    previewInvoiceModal,
    previewOrderModal
  }
}
</script>
<style>
</style>