<template>
  <v-menu
    ref="datePicker"
    v-model="datePicker"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :dense="dense"
        :outlined="outlined"
        :filled="filled"
        hide-details
        :value="dateFormatted"
        :disabled="disabled"
        @blur="date = parseDate(dateFormatted)"
        @focus="$event.target.select()"
        @keydown.enter="save(formatDate($event.target.value))"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      locale="nl"
      first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="closeDatePicker">Annul.</v-btn>
      <v-btn text color="primary" @click="save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "datePicker",
  props: {
    value: String,
    label: String,
    dateFormatted: String,
    disabled: Boolean,
    outlined: Boolean,
    filled: Boolean,
    dense: Boolean,
  },
  data() {
    return {
      datePicker: false,
      date: this.value
    }
  },
  methods: {
    save(date) {
      this.$refs.datePicker.save(date)
      this.$emit("changeDate", date)
    },
    closeDatePicker() {
      this.datePicker=false
    },
    parseDate(d) {
      let date
      if (!d) date = null
      else {
        const [day, month, year] = d.split("-")
        date = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
      }
      this.$emit("input", date)
    },
formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
  }
}
</script>

<style>
</style>