<template>
  <v-dialog
    :value="previewInvoiceModal"
    persistent
    fullscreen
  >
    <v-card>
        <v-card-title>{{invoice.client.name}}</v-card-title>
      <v-row>
        <v-col>
            <div class="settings">
             <label>Briefpapier:</label
              ><input
                @change="viewInvoice"
                type="checkbox"
                v-model="options.stationary"
              />
          </div>
        </v-col>
        <v-col>
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>          
        </v-col>

        <v-footer fixed class="d-flex justify-center">
            <v-btn @click="closePreviewInvoiceModal" color="primary"
              >Sluit</v-btn
            >
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewInvoice } from "../js/pdfController"
export default {
  name: "previewInvoiceModal",
  props: {
    previewInvoiceModal: Boolean,
    invoice: {}
  },
  data() {
    return {
      dataUrl: "",
      options: {
          includePrices: false
      }
    }
  },
  methods: {
    closePreviewInvoiceModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-preview-invoice-modal")
    },
    async viewInvoice() {
      let data = await viewInvoice(this.invoice, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  async mounted() {
      this.options.includePrices = this.invoice.client.includePrices
    await this.viewInvoice(this.invoice, this.options)
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>