<template>
  <div class="autocomplete">
    <v-text-field
      label="Naam"
      text
      @input="searchCOC"
      @click="clearInput"
      :value="searchString"
      autocomplete="off"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      placeholder="Zoek naar klant"
    >
      <!-- <template v-slot:append>
        <v-btn small text @click="isOpen = true">Filter</v-btn>
      </template> -->
    </v-text-field>
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
      :class="{ noResults: noResults }"
    >
      <!-- <li class="sticky">
        <v-row no-gutters>
          <v-col
            ><v-text-field
              clearable
              label="Klantnr"
              v-model="COCNumber"
              type="number"
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field clearable label="Stad" v-model="city"></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              clearable
              label="Postcode"
              v-model="postalcode"
              type="number"
            ></v-text-field
          ></v-col>
          <v-col><v-btn @click="searchCOC">Zoeken</v-btn></v-col>
        </v-row>
      </li> -->
      <li class="loading" v-if="isLoading">Bezig met zoeken...</li>
      <li
        v-else
        v-for="(result, i) in searchResults"
        :key="i"
        @click="fetchCOC(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.handelsnaam }}
        <span class="d-flex"
          >{{ result.straatnaam }},
          {{ result.plaats }}</span
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { config } from "../config/config.mjs"
import { errorHandler } from '../js/functions'
var timeout
// import { fetchGET } from '../js/functions'
export default {
  name: "searchCOC",
  props: {
    noResults: Boolean,
    searchString: String
  },
  data() {
    return {
      isAsync: false,
      isOpen: false,
      isLoading: false,
      arrowCounter: 0,
      searchResults: [],
      name: "",
      COCNumber: "",
      city: "",
      postalcode: ""
    }
  },
  methods: {
    async searchCOC(e) {
      if (e && typeof e == "string") {
        this.$emit("setSearchString", e)
        this.name = e
      }
      this.isOpen = this.noResults ? false : true
      this.isLoading = true
      clearTimeout(timeout)
      if (this.name.length > 3) {
        timeout = setTimeout(async () => {
          try {
            let res = await fetch(`${config.kvk.host}/zoeken/${this.name}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
          })
          let results = await res.json()
          console.log(results)
            this.isLoading = false
            this.searchResults = results.resultaten
          } catch (e) {
            errorHandler(e,"Niets gevonden")
          }          
        }, 2000)
      }
    },
    async fetchCOC(result) {
      this.$emit("setSearchString", result.handelsnaam)
      this.isOpen = false
      this.COCNumber = ""
      this.city = ""
      this.postalcode = ""
          let res = await fetch(`${config.kvk.host}/basisprofielen/${result.kvkNummer}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
          })
          let results = await res.json()
          console.log(results)
          this.$emit("get-results", results)
    },
    clearInput() {
      this.isOpen = false
      this.name = ""
      this.searchResults = []
      this.$emit("clear-input")
    },
    onArrowDown() {
      if (this.arrowCounter < this.searchResults.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter() {
      let result = this.searchResults[this.arrowCounter]
      this.$emit("setSearchString", result.name)
      this.$emit("get-results", result)
      this.isOpen = false
      this.arrowCounter = -1
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside)
  },
  destroyed() {
    clearTimeout(timeout)
    document.removeEventListener("click", this.handleClickOutside)
  }
}
</script>
<style scoped>
.noResults {
  height: 80px;
}
.autocomplete {
  position: relative;
  margin: auto;
}
.autocomplete input {
  text-align: center;
  height: 30px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.autocomplete-results {
  font-size: 30px;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  overflow: auto;
  width: 600px;
  position: absolute;
  background: white;
  z-index: 99;
}
.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  color: black;
}
.autocomplete-result span {
  font-size: 0.5em;
}
.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #69a9e4;
  color: white;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  background: white;
  position: sticky;
  top: 0;
}
</style>