<template>
  <v-dialog
    :value="printOrderModal"
    persistent
    fullscreen
    @keydown.enter="print"
  >
    <v-card>
      <v-card-title>Print order</v-card-title>
      <v-row>
        <v-col cols="6">
          <input
            class="hidden"
            id="input"
            @focus="closePrintOrderModal"
            type="text"
          />
          <div class="settings">
            <label>Toon prijzen:</label
            ><input
              @change="viewOrder"
              type="checkbox"
              v-model="options.includePrices"
            />
            <label>Sjabloon:</label>
           <select @change="viewOrder" v-model="options.orderType">
             <option v-for="status of options.orderTypes" :value="status.status" :key="status.status">{{status.text}}</option>
           </select>            
            <template v-if="isElectron">              
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model.number="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
        </v-col>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closePrintOrderModal" color="primary">Sluit</v-btn>
            <v-btn color="primary" @click="print">Afdrukken</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewOrder, printOrder } from "../js/pdfController"
export default {
  name: "printOrderModal",
  props: {
    printOrderModal: Boolean,
    order: {}
  },
  data() {
    return {
      dataUrl: "",
      buffer: "",
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        includePrices: false,
        pageSize: "A4",
        copies: 1,
        printer: "",
        orderType: 0,
        orderTypes:[
        {status:0,text:"Pakbon"},
        {status:1,text:"Opdrachtbevestiging"},
      ]
      }
    }
  },
  methods: {
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printOrder()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printOrder()
      } else {
        document
          .getElementById("iframe")
          .addEventListener("load", this.iframeOnload)
        await this.viewOrder()
        this.iframeLoaded = true
      }
    },
    async printOrder() {
      localStorage.setItem("printOrderOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printOrder(this.order, this.options)        
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
        window.ipcRenderer.receive("consoleLog", data => {
        console.log(data)
      })
      } else document.getElementById("iframe").contentWindow.print()
      let order = this.order
      order.printed= true
      order.status = order.status <= 2 ? 2 : order.status
      await this.$store.dispatch("fetchPOST",{type:"orderPrinted",data:order})
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintOrderModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-order-modal")
    },
    async viewOrder() {
      let data = await viewOrder(this.order, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  computed: {
    isElectron() {
      return this.$store.state.isElectron 
    },
    printers(){
      return this.$store.state.printers
    }
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("printOrderOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printOrderOptions"))
      this.options = obj
    }
    this.options.includePrices = this.order.client.includePrices
    await this.viewOrder(this.order, this.options)
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
input,select {
  border: 1px solid black;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>