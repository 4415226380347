<template>
  <div class="autocomplete">
    <v-text-field
      prepend-inner-icon="mdi-account-search"
      label="Artikel"
      text
      @input="searchItem"
      @click="clearInput"
      :value="searchString"
      autocomplete="off"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      placeholder="Zoek naar artikel"
      :autofocus="autoFocus"
      :outlined="outlined"
    >
      <template v-slot:append>
        <v-btn small text @click="isOpen = true">Filter</v-btn>
      </template>
    </v-text-field>
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
      :class="{ noResults: noResults }"
    >
      <li class="sticky">
        <v-row no-gutters>
          <v-col
            ><v-text-field
              clearable
              label="Artikelnr"
              v-model="itemNumber"
              type="number"
            ></v-text-field
          ></v-col>
          <v-col><v-btn @click="searchItem">Zoeken</v-btn></v-col>
        </v-row>
      </li>
      <li class="loading" v-if="isLoading">Bezig met zoeken...</li>
      <li
        v-else
        v-for="(result, i) in searchResults"
        :key="i"
        @click="setSearch(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.name }}
        <span class="d-flex">{{ result.category }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { config } from "../config/config"
export default {
  name: "searchItem",
  props: {
    noResults: Boolean,
    searchString: String,
    outlined: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isAsync: false,
      isOpen: false,
      isLoading: false,
      arrowCounter: 0,
      searchResults: [],
      name: "",
      itemNumber: "",
    }
  },
  methods: {
    async searchItem(e) {
      if (e && typeof e == "string") {
        this.$emit("setSearchString", e)
        this.name = e
      }
      this.isOpen = this.noResults ? false : true
      this.isLoading = true
      let event = "searchItem"
      let data = JSON.stringify({
        name: this.name || undefined,
        itemNumber: parseInt(this.itemNumber) || undefined,
      })
      let res = await fetch(`${config.api.host}/${event}/${data}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include"
      })
      let results = await res.json()
      if (this.noResults) {
        this.itemNumber = ""
        this.$emit("get-results", results)
      }
      else {
        this.isLoading = false
        this.searchResults = results
      }
    },
    clearInput() {
      this.isOpen = false
      this.name = ""
      this.searchResults = []
      this.$emit("clear-input")
    },
    setSearch(result) {
      this.$emit("setSearchString", result.name)
      this.$emit("get-results", result)
      this.isOpen = false
      this.itemNumber = ""
    },
    onArrowDown() {
      if (this.arrowCounter < this.searchResults.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter() {
      let result = this.searchResults[this.arrowCounter]
      this.$emit("setSearchString", result.name)
      this.$emit("get-results", result)
      this.isOpen = false
      this.arrowCounter = -1
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside)
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside)
  }
}
</script>
<style scoped>
.noResults {
  height: 80px;
}
.autocomplete {
  position: relative;
  margin: auto;
}
.autocomplete input {
  text-align: center;
  height: 30px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.autocomplete-results {
  font-size: 30px;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  overflow: auto;
  width: 600px;
  position: absolute;
  background: white;
  z-index: 99;
}
.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  color: black;
}
.autocomplete-result span {
  font-size: 0.5em;
}
.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #69a9e4;
  color: white;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  background: white;
  position: sticky;
  top: 0;
}
</style>