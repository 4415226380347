<template>
  <v-dialog :value="printPaymentsModal" persistent fullscreen>
      <v-card>
      <v-card-title>Print betalingen</v-card-title>
      <v-row>
        <v-col cols="6">
          <input
            class="hidden"
            id="input"
            @focus="closePrintPaymentsModal"
            type="text"
          />
          <div class="settings">            
            <template v-if="isElectron">              
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
        </v-col>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closePrintPaymentsModal" color="primary">Sluit</v-btn>
            <v-btn color="primary" @click="print">Afdrukken</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewPayments, printPayments } from "../js/pdfController"
export default {
  props: { printPaymentsModal: Boolean, client: Object, payments: Array },
  name: "printPaymentsModal",
  data() {
    return {
      dataUrl: "",
      buffer: "",
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        pageSize: "A4",
        copies: 1,
        printer: ""
      }
    }
  },
  methods: {
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printPayments()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printPayments()
      } else {
        document
          .getElementById("iframe")
          .addEventListener("load", this.iframeOnload)
        await this.viewPayments()
        this.iframeLoaded = true
      }
    },
    async printPayments() {
      localStorage.setItem("printPaymentsOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printPayments(
          this.client,
          this.payments,
          this.options
        )
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else document.getElementById("iframe").contentWindow.print()
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintPaymentsModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-payments-modal")
    },
    async viewPayments() {
      let data = await viewPayments(this.client, this.payments, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  computed: {
    isElectron() {
      return this.$store.state.isElectron
    },
    printers() {
      return this.$store.state.printers
    }
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("printPaymentsOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printPaymentsOptions"))
      this.options = obj
    }
    await this.viewPayments(this.client, this.payments, this.options)
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
input,select {
  border: 1px solid black;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>