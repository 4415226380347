<template>
  <v-dialog :value="promptModal" max-width="400">
    <v-card>

<h3 class="text-center">{{promptTitle}}</h3>
<slot>
</slot>
      <v-card-text class="d-flex justify-center pa-2">{{promptText}}</v-card-text>
  <div class="d-flex justify-end pa-2">
    <v-btn @click="cancel" tile color="primary">Annuleer</v-btn>
    <v-btn @click="confirm" tile color="primary">Verwijder</v-btn>
     </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    promptTitle: String,
    promptModal: Boolean,
    promptText: String,
    data: Object
  },
  methods: {
    confirm() {
      this.$emit("confirm")
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>
table{
  text-align: left;
}
th,tr{
  width:100px;
}
</style>