<template>
  <v-dialog :value="salePriceModal" persistent width="400">
    <v-card>
      <v-card-title>Aanbieding</v-card-title>
      <v-text-field
        type="number"
        label="Prijs"
        v-model="thisItem.salePrice"
      ></v-text-field>
      <date-picker
        :value="thisItem.fromDate"
        label="Vanaf"
        :dateFormatted="fromDateFormatted"
        @changeDate="changeFromDate"
      ></date-picker>
      <date-picker
        :value="thisItem.toDate"
        label="t/m"
        :dateFormatted="toDateFormatted"
        @changeDate="changeToDate"
      ></date-picker>
      <v-footer class="d-flex justify-center">
        <v-btn color="primary" tile @click="closeSalePriceModal"
          >Annuleer</v-btn
        >
        <v-btn color="primary" tile @click="insertSalePrice">Opslaan</v-btn>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import datePicker from "@/components/datePicker.vue"
export default {
  name: "salePriceModal",
  props: {
    item: Object,
    salePriceModal: Boolean
  },
  data() {
    return {
      thisItem: {
        itemName: this.item.itemName,
        itemNumber: this.item.itemNumber,
        salePrice: "",
        fromDate: "",
        toDate: ""
      },
      fromDateFormatted: "",
      toDateFormatted: ""
    }
  },
  methods: {
    changeFromDate(date) {
      this.thisItem.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.thisItem.fromDate > this.thisItem.toDate) {
        this.thisItem.toDate = this.thisItem.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
    },
    changeToDate(date) {
      this.thisItem.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.thisItem.toDate < this.thisItem.fromDate) {
        this.thisItem.fromDate = this.thisItem.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    insertSalePrice() {
        console.log(this.thisItem)
      this.$emit("insert-sale-price", this.thisItem)
    },
    closeSalePriceModal() {
      this.$emit("close-sale-price-modal")
    }
  },
  components: {
    datePicker
  }
}
</script>

<style scoped>
</style>