<template>
  <v-dialog
    :value="previewOrderModal"
    persistent
    fullscreen
  >
    <v-card>
        <v-card-title>{{order.name}}</v-card-title>
      <v-row>
        <v-col>
            <label>Toon prijzen:</label
            ><input
              @change="viewOrder"
              type="checkbox"
              v-model="options.includePrices"
            />
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>          
        </v-col>

        <v-footer fixed class="d-flex justify-center">
            <v-btn @click="closePreviewOrderModal" color="primary"
              >Sluit</v-btn
            >
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewOrder } from "../js/pdfController"
export default {
  name: "previewOrderModal",
  props: {
    previewOrderModal: Boolean,
    order: {}
  },
  data() {
    return {
      dataUrl: "",
      options: {
          includePrices: false
      }
    }
  },
  methods: {
    closePreviewOrderModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-preview-order-modal")
    },
    async viewOrder() {
      let data = await viewOrder(this.order, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  async mounted() {
      this.options.includePrices = this.order.client.includePrices
    await this.viewOrder(this.order, this.options)
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>