<template>
  <v-dialog
    :value="printQuoteModal"
    persistent
    fullscreen
    @keydown.enter="print"
  >
    <v-card>
      <v-card-title>Print offerte</v-card-title>
      <v-row>
        <v-col cols="6">
          <input
            class="hidden"
            id="input"
            @focus="closePrintQuoteModal"
            type="text"
          />
          <div class="settings">            
            <template v-if="isElectron">              
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
        </v-col>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closePrintQuoteModal" color="primary">Sluit</v-btn>
            <v-btn color="primary" @click="print">Afdrukken</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewQuote, printQuote } from "../js/pdfController"
export default {
  name: "printQuoteModal",
  props: {
    printQuoteModal: Boolean,
    quote: {}
  },
  data() {
    return {
      dataUrl: "",
      buffer: "",
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        includePrices: false,
        pageSize: "A4",
        copies: 1,
        printer: ""
      }
    }
  },
  methods: {
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printQuote()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printQuote()
      } else {
        document
          .getElementById("iframe")
          .addEventListener("load", this.iframeOnload)
        await this.viewQuote()
        this.iframeLoaded = true
      }
    },
    async printQuote() {
      localStorage.setItem("printQuoteOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printQuote(this.quote, this.options)        
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else document.getElementById("iframe").contentWindow.print()
      let quote = this.quote
      quote.printed= true
      quote.status = quote.status == 0 ? 1 : quote.status
      await this.$store.dispatch("fetchPOST",{type:"quotePrinted",data:quote})
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintQuoteModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-quote-modal")
    },
    async viewQuote() {
      let data = await viewQuote(this.quote, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  computed: {
    isElectron() {
      return this.$store.state.isElectron 
    },
    printers(){
      return this.$store.state.printers
    }
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("printQuoteOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printQuoteOptions"))
      this.options = obj
    }
    this.options.includePrices = true
    await this.viewQuote(this.quote, this.options)
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
input,select {
  border: 1px solid black;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>