<template>
  <v-dialog :value="fixedPriceModal" fullscreen persistent>
    <v-app-bar fixed height="50">
      <v-row>
      <v-col cols="3">
      <search-item
                :searchString="searchString"
                :no-results="true"
                @setSearchString ="setSearchString"
                @get-results="getItemResults"
                @clear-input="clearItem"
              ></search-item>  
    </v-col>
    <v-col><v-btn-toggle>
      <v-btn @click="selectCategory('')" color="primary">Alles</v-btn>
    <v-btn color="primary" @click="selectCategory(category)" v-for="category of categories" :key="category">{{category}}</v-btn></v-btn-toggle></v-col>
    <v-col><v-btn @click="closeFixedPriceModal" color="primary" height="47">Sluiten</v-btn></v-col>
    </v-row>
    </v-app-bar>
    <v-card>
      <v-row>
        <v-col class="mt-15">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          class="elevation-1"
          item-key="id"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
          'items-per-page-options': [10,25,50,100]}"
    >
     <template v-slot:[`item.price`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.price"
                @save="insertFixedPrice(props.item,props.item.price)"
              >
                {{ toCurrency(props.item.price) }}
                <template v-slot:input>
                  <v-text-field
                  type="number"
                    v-model="props.item.price"
                    @focus="$event.target.select()"
                    @change="props.item.price=commaToDot($event)"
                    label="Prijs"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
        </v-data-table>
        </v-col>
        <v-col class="mt-15">
          <v-card style="position:fixed;width:500px">
            <v-card-title>Toegevoegd</v-card-title>
            <v-list>
              <v-list-item v-for="item of fixedPricesList" :key="item._id">
                <v-list-item-content>{{item.name}}</v-list-item-content>
                <v-list-item-content>{{toCurrency(item.price)}}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {fetchGET, toCurrency} from "../js/functions.js"
import searchItem from '@/components/searchItem.vue'
export default {
  name: "fixedPriceModal",
  props: {
    fixedPriceModal: Boolean,
    fixedPricesList: Array
  },
  data() {
    return {
      searchString: "",    
      searchItems: "",
      itemNumber: "",
      selectedCategory: "",
      items: [],
      totalItems: 0,
      thisClient: {},
      fixedPrice: 0,
      headers: [
        { text: "Artikelnr", value: "itemNumber",width:100 },
        { text: "Naam", value: "name",align:"start" },
        { text: "Verkoopprijs", value: "price" }
      ],
      options:{
        page:1,
        itemsPerPage: 50,
        sortBy:["itemNumber"],
        sortDesc: [false]
      }
    }
  },
  methods: {
    toCurrency(n) {
      return toCurrency(n)
    },
    commaToDot(price){
      console.log(typeof price)
     let fixedPrice = typeof price==='string'?price.replace(",","."):price.toString().replace(",",".")
     return fixedPrice
    },
    setSearchString(val){
this.searchString = val
    },
    clearItem(){
      this.options.page = 1
      this.searchString= ""
      this.fetchItems()
    },
    getItemResults(val){
      this.items = val
    },
    async fetchItems(){
      let response = await fetchGET("fetchItems",{category: this.selectedCategory,options:this.options})
     this.items = response[0].results.length ? response[0].results : []
     this.totalItems = response[0].length.length ? response[0].length[0].length : 0
    },
    selectCategory(category){
      this.options.page = 1
      this.selectedCategory = category
      this.fetchItems()
    },
    getClient(client) {
      this.thisClient = client
    },
    clearClient() {
      this.thisClient = {}
    },
    insertFixedPrice(item,price) {
    let fixedPrice = this.commaToDot(price)
      this.$emit("insert-fixed-price",item, fixedPrice)
    },
    closeFixedPriceModal() {
      this.$emit("close-fixed-price-modal")
    }
  },
  watch: {
    options(newValue, oldValue) {
      let changed = false
      if(oldValue.page!==newValue.page)changed=true
      if(oldValue.itemsPerPage!==newValue.itemsPerPage)changed=true
      if(oldValue.sortBy!==newValue.sortBy)changed=true
      if(oldValue.sortDesc!==newValue.sortDesc)changed=true
      if(changed)this.fetchItems()
    }
  },
  computed:{
    filteredItems(){
      let items = this.items
      if(this.searchItems) items = items.filter(item=>item.name.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1)
      return items
    },
    categories(){
      return this.$store.state.settings.categories
    }
  },
  mounted(){
this.fetchItems()
  },
  components: {searchItem }
}
</script>

<style>
</style>