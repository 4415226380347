<template>
  <v-dialog
    :value="emailOpenOrdersModal"
    persistent
    fullscreen
    @keydown.enter="email"
  >
    <v-card>
      <v-row>
        <v-col cols="6">
          <div class="settings">
            <label>Alles:</label
            ><input
              @change="viewOpenOrders"
              type="checkbox"
              v-model="allDates"
            />
            <label>Vanaf:</label
            ><input
              @change="viewOpenOrders"
              type="date"
              v-model="fromDate"
              :disabled="allDates"
            />
            <label>t/m:</label
            ><input
              @change="viewOpenOrders"
              type="date"
              v-model="toDate"
              :disabled="allDates"
            />
            <label>Aan:</label><input type="text" v-model="headers.to" />
            <label>Bericht:</label>
            <vue-editor v-if="dataUrl" v-model="headers.html"></vue-editor>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>
        </v-col>

        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closeEmailOpenOrdersModal" color="primary"
              >Sluit</v-btn
            >
            <v-btn color="primary" @click="email">Mailen</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  viewOpenOrders,
  emailOpenOrders
} from "../js/pdfController"
import { VueEditor } from "vue2-editor"
import { errorHandler, fetchGET, fetchPOST, successHandler } from "../js/functions"
export default {
  name: "emailOpenOrdersModal",
  props: {
    emailOpenOrdersModal: Boolean,
    client: Object,
    data: Array
  },
  data() {
    return {
      openOrders: this.data,
      dataUrl: "",
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      allDates: true,
      headers: {
        subject: "Te factureren orders",
        fileName: `Te factureren orders ${new Date(
          Date.now()
        ).toLocaleDateString("nl-NL")}.pdf`,
        to: "",
        text: "",
        html: ""
      },
    }
  },
  methods: {
    async email() {
      try {
        this.closeEmailOpenOrdersModal()
        let openOrders = this.allDates
          ? this.openOrders
          : this.openOrders.filter(
              order =>
                new Date(order.date) >= new Date(this.fromDate) &&
                new Date(order.date) <= new Date(this.toDate)
            )
        let buffer = await emailOpenOrders(
          this.client,
          openOrders
        )
        let response = await fetchPOST("sendMail", {
          buffer: buffer,
          headers: this.headers
        })
        if (response.success) successHandler("Email verzonden")
          else throw "Email niet verzonden"
      } catch (e) {
        errorHandler(e,"Email niet verzonden")
      }
    },
    closeEmailOpenOrdersModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-email-open-orders-modal")
    },
    async viewOpenOrders() {
      let openOrders = this.allDates
        ? this.openOrders
        : this.openOrders.filter(
            order =>
              new Date(order.date) >= new Date(this.fromDate) &&
              new Date(order.date) <= new Date(this.toDate)
          )
      let data = await viewOpenOrders(this.client, openOrders)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  async mounted() {
    await this.viewOpenOrders()
    this.headers.text = this.$store.state.settings.emailOpenOrdersBody.text
    this.headers.html = this.$store.state.settings.emailOpenOrdersBody.html
    let client = await fetchGET("findClient", this.client.clientNumber)
    this.headers.to = client.email
  },
  components: {
    VueEditor
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>