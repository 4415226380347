<template>
  <v-dialog
    :value="printOutstandingInvoicesModal"
    persistent
    fullscreen
    @keydown.enter="print"
  >
    <v-card>
      <v-row>
        <v-col cols="6">
          <div>
            <input
              class="hidden"
              id="input"
              @focus="closePrintOutstandingInvoicesModal"
              type="text"
            />
          </div>
          <div class="settings">
            <label>Alles:</label
            ><input
              @change="viewOutstandingInvoices"
              type="checkbox"
              v-model="allDates"
            />
            <label>Vanaf:</label
            ><input
              @change="viewOutstandingInvoices"
              type="date"
              v-model="fromDate"
              :disabled="allDates"
            />
            <label>t/m:</label
            ><input
              @change="viewOutstandingInvoices"
              type="date"
              v-model="toDate"
              :disabled="allDates"
            />
            <template v-if="isElectron">              
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>
        </v-col>

        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closePrintOutstandingInvoicesModal" color="primary"
              >Sluit</v-btn
            >
            <v-btn color="primary" @click="print">Afdrukken</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { viewOutstandingInvoices,printOutstandingInvoices } from "../js/pdfController"
export default {
  name: "printOutstandingInvoicesModal",
  props: {
    printOutstandingInvoicesModal: Boolean,
    client: {},
    data: Array
  },
  data() {
    return {
      outstandingInvoices: this.data,
      dataUrl: "",
      buffer: "",
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      allDates: true,
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        pageSize: "A4",
        copies: 1,
        printer: ""
      }
    }
  },
  methods: {
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printOutstandingInvoices()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printOutstandingInvoices()
      } else {
      document
        .getElementById("iframe")
        .addEventListener("load", this.iframeOnload)
      await this.viewOutstandingInvoices()
      this.iframeLoaded = true
      }
    },
    async printOutstandingInvoices() {
      localStorage.setItem("printOutstandingInvoicesOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let outstandingInvoices = this.allDates
        ? this.outstandingInvoices
        : this.outstandingInvoices.filter(
            invoice =>
              new Date(invoice.date) >= new Date(this.fromDate) &&
              new Date(invoice.date) <= new Date(this.toDate)
          )
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printOutstandingInvoices(this.client, outstandingInvoices)
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else
      document.getElementById("iframe").contentWindow.print()
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintOutstandingInvoicesModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-outstanding-invoices-modal")
    },
    async viewOutstandingInvoices() {
      let outstandingInvoices = this.allDates
        ? this.outstandingInvoices
        : this.outstandingInvoices.filter(
            invoice =>
              new Date(invoice.date) >= new Date(this.fromDate) &&
              new Date(invoice.date) <= new Date(this.toDate)
          )
      let data = await viewOutstandingInvoices(this.client, outstandingInvoices)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
    computed: {
    isElectron() {
      return this.$store.state.isElectron 
    },
    printers(){
      return this.$store.state.printers
    }
  },
  async mounted() {
     if (JSON.parse(localStorage.getItem("printOutstandingInvoicesOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printOutstandingInvoicesOptions"))
      this.options = obj
    }
    await this.viewOutstandingInvoices()
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>